import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/achievement',
    name: 'achievement',
    component: () => import('./components/achievement.vue')
  },
  {
    path: '/',
    name: '',
    component: () => import('./components/museum.vue')
  },
  {
    path: '/museum',
    name: '',
    component: () => import('./components/museum.vue')
  }, 
  {
    path: '/timeCapsule',
    name: '',
    component: () => import('./components/timeCapsule.vue')
  }, 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  }
});

export default router;